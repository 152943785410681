import React, { useState } from "react";
import { subDays } from "date-fns";
import { includes } from "lodash";
import { useRouter } from "next/router";

import { Seo, Container, Navbar, Status, Select } from "~components";
import {
  usePermissions,
  useUser,
  useSuperAdmin,
  useOrganization,
  useIsMobile,
} from "~hooks";

import { NAV_ITEMS } from "~components/common/Navbar";
import { Card, OverviewSummary, EventSummaryCard } from "~components/reports";
import { OrderByDirection, Role, ReportingFilterSource } from "~graphql/sdk";
import { withAuth } from "~lib/helpers";
import { Box, Flex } from "flicket-ui";
import { useQuery } from "~hooks/useQuery";
import {
  DashboardEventsDocument,
  SummaryOverviewDocument,
} from "~graphql/typed-document-nodes";
import { OrganizationMenu } from "~components/common/Layout/OrganizationMenu";

const Home = () => {
  const router = useRouter();
  const [selectedEventId, setSelectedEventId] = useState("");
  const { user, isLoggedIn, isEventManager } = useUser();
  const { hasPermissions, Permission } = usePermissions();
  const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();
  const { hasFeature } = useOrganization();
  const isMobile = useIsMobile();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const { data: eventsData, error: eventsError, isLoading } = useQuery(
    DashboardEventsDocument,
    {
      where: {
        startDate: today.toISOString(),
        endDate: undefined,
      },
      orderBy: { startDate: OrderByDirection.Asc },
    }
  );

  const events = eventsData?.events?.edges?.map(({ node }) => node);

  if (isSuperAdmin && isSuperAdminDomain) {
    router.replace("/accounts").catch((e) => console.error(e));
    return null;
  } else if (includes(user?.roles, Role.PosAdmin) && user?.roles.length === 1) {
    router.replace("/pos").catch((e) => console.error(e));
    return null;
  }

  if (!isLoggedIn) {
    router.replace("/login").catch((e) => console.error(e));
    return null;
  }

  if (!hasPermissions(Permission.ReportingFinancial) || isEventManager) {
    const firstRoute = NAV_ITEMS({ hasPermissions, user, hasFeature })[0];
    router.replace(firstRoute.url).catch((e) => console.error(e));
    return null;
  }

  const { data: summaryOverviewData, error: errorOverview } = useQuery(
    isMobile === false && SummaryOverviewDocument,
    {
      channel: null,
      source: ReportingFilterSource.Overall,
      startDate: subDays(today, 365).toISOString(),
      endDate: today.toISOString(),
    }
  );

  const summaryOverview = summaryOverviewData?.summaryOverview;

  const selectedEvents =
    selectedEventId === ""
      ? events
      : events?.filter((event) => selectedEventId === event.id);

  const options = events?.map((event) => ({
    label: event.title,
    value: event.id,
  }));
  options?.unshift({ label: "All Events", value: "" });

  return (
    <>
      <Seo title="Flicket | Dashboard" description="Flicket dashboard" />

      <Box
        pl={{ _: 0, xs: 96 as any }}
        pt={{ _: 4, xs: 0 }}
        pb={{ _: 10, xs: 0 }}
        width="100vw"
        height="100vh"
        bg="N100"
        overflowY="auto"
      >
        <Navbar />
        <Container flexDirection="column" py={{ _: 0, xs: 6 }}>
          <Flex justifyContent="flex-end" mb={2}>
            <OrganizationMenu />
          </Flex>
          {!isMobile && (
            <OverviewSummary
              data={summaryOverview}
              error={errorOverview}
              mb={4}
            />
          )}

          <Card title="Upcoming events">
            <Status
              loading={!eventsError && !events && !isLoading}
              error={eventsError}
            >
              {!isMobile &&
                events?.map((event) => (
                  <EventSummaryCard key={event.id} event={event} />
                ))}

              {isMobile && events && (
                <Select
                  pb={4}
                  isMulti={false}
                  name="event-selector"
                  options={options}
                  onChange={setSelectedEventId}
                  value={selectedEventId}
                  small
                />
              )}

              {isMobile &&
                selectedEvents &&
                selectedEvents.map((selectedEvent) => (
                  <EventSummaryCard
                    key={selectedEvent.id}
                    event={selectedEvent}
                  />
                ))}
            </Status>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default withAuth(Home);
